import React from "react";

const RikPin = ({
  style = {},
  fill = "#000",
  width = "100%",
  className = "navshadow2",
  viewBox = "0 0 62.811 63.851"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Group_41" data-name="Group 41" transform="translate(4028.669 1130.657)">
    <path id="Path_338" data-name="Path 338" d="M-1661.338,280.784h13.321l-7.552,7.552-7.552-7.552Z" transform="translate(-2342 -1355.143)" fill="#ffef00"/>
    <path id="Path_262" data-name="Path 262" d="M9.83,86.044s5.219,4.493,29.08,4.493c24.434,0,28.491-4.086,28.64-4.237a86.9,86.9,0,0,0-31.288-4.091c-6.738.433-26.433,3.835-26.433,3.835" transform="translate(-4036.174 -1193.301)" fill="#9e151a"/>
    <path id="Path_263" data-name="Path 263" d="M225.038,127.84c-.393,4-3.431,6.975-6.788,6.646s-5.759-3.838-5.366-7.836l.213-2.184c.393-4,3.431-6.975,6.788-6.646s5.759,3.838,5.368,7.836Z" transform="translate(-4191.153 -1220.582)" fill="#df9563"/>
    <path id="Path_264" data-name="Path 264" d="M.257,127.84c.393,4,3.431,6.975,6.788,6.646s5.759-3.838,5.368-7.836l-.215-2.184c-.393-4-3.431-6.975-6.786-6.646s-5.761,3.838-5.368,7.836Z" transform="translate(-4028.671 -1220.582)" fill="#df9563"/>
    <path id="Path_265" data-name="Path 265" d="M76.668,87.214c0,10.484-9.98,17.911-22.177,17.911h-6c-12.2,0-22.179-7.429-22.179-17.911,0-.1,0-10.486,0-10.585C26.5,66.28,36.4,62.61,48.489,62.61h6c12.119,0,22.049,3.717,22.177,14.106v10.5" transform="translate(-4048.754 -1178.453)" fill="#eda26a"/>
    <path id="Path_266" data-name="Path 266" d="M66.3,12.2c0,5.82-13.271,3.726-29.639,3.726S7.02,18.019,7.02,12.2,20.289,0,36.657,0,66.3,6.381,66.3,12.2" transform="translate(-4034.028 -1130.657)" fill="#be1622"/>
    <path id="Path_267" data-name="Path 267" d="M73.8,47.894S65.1,44.44,46.958,44.44s-25.709,4.133-25.709,4.133-.958,3.885-.686,4.912-.277,1.779,3.842,1.779,49.878-.523,49.878-.523.542-6.024-.483-6.847" transform="translate(-4044.33 -1164.582)" fill="#9e151a"/>
    <path id="Path_268" data-name="Path 268" d="M64.244,81.019a1.7,1.7,0,0,1-.341-.035,153.077,153.077,0,0,0-27.819-2.5c-17.769,0-27.649,2.463-27.748,2.487a1.656,1.656,0,0,1-.819-3.208c.414-.106,10.335-2.588,28.567-2.588a153.386,153.386,0,0,1,28.494,2.572,1.656,1.656,0,0,1-.334,3.277" transform="translate(-4033.458 -1188.042)" fill="#c01622"/>
    <path id="Path_269" data-name="Path 269" d="M127.783,4.17a.816.816,0,0,0-.814.812.827.827,0,0,0,.809.845c16,.35,24.32,5.787,24.4,5.842a.827.827,0,0,0,.92-1.375c-.348-.234-8.759-5.761-25.288-6.123h-.031" transform="translate(-4125.598 -1133.84)" fill="#ca4b3f"/>
    <path id="Path_270" data-name="Path 270" d="M155.643,124.04c-2.8,0-5.073,2.456-5.073,4.058v.125l.092.085a7.43,7.43,0,0,0,4.98,1.538,8.085,8.085,0,0,0,4.966-1.526l.1-.088V128.1c0-1.351-2.193-4.055-5.07-4.055m.338.591a2.413,2.413,0,0,1,.887-.166,2.5,2.5,0,0,1,2.416,1.954,3.4,3.4,0,0,1,.854,1.543,6.719,6.719,0,0,1-1.4.741,2.449,2.449,0,0,1-1.872.873,2.4,2.4,0,0,1-1.174-.3h-.052a7.325,7.325,0,0,1-4.5-1.306c.125-1.346,2.136-3.35,4.5-3.35a3.229,3.229,0,0,1,.341.014" transform="translate(-4143.614 -1225.349)" fill="#48360f"/>
    <path id="Path_271" data-name="Path 271" d="M169.174,125.83a2.453,2.453,0,0,0-.887.166,2.586,2.586,0,0,0-.286,4.64,2.406,2.406,0,0,0,1.174.3,2.457,2.457,0,0,0,1.872-.873,2.586,2.586,0,0,0,.615-1.682,2.625,2.625,0,0,0-.069-.6,2.505,2.505,0,0,0-2.418-1.954m-.573,4.126a1.69,1.69,0,0,1,.26-3.22,2.618,2.618,0,0,0-.658,1.782,2.764,2.764,0,0,0,.4,1.439" transform="translate(-4155.92 -1226.715)" fill="#48360f"/>
    <path id="Path_272" data-name="Path 272" d="M152.3,113.61a7.281,7.281,0,0,0-5.57,2.612l.442.355a6.8,6.8,0,0,1,5.127-2.392,7.964,7.964,0,0,1,5.6,2.406l.419-.383a8.376,8.376,0,0,0-6.017-2.6" transform="translate(-4140.683 -1217.387)" fill="#48360f"/>
    <path id="Path_273" data-name="Path 273" d="M78.733,124.04c-2.8,0-5.073,2.456-5.073,4.058v.125l.09.085a7.43,7.43,0,0,0,4.98,1.538A8.085,8.085,0,0,0,83.7,128.32l.1-.088V128.1c0-1.351-2.191-4.055-5.068-4.055m.338.591a2.4,2.4,0,0,1,.887-.166,2.5,2.5,0,0,1,2.418,1.954,3.412,3.412,0,0,1,.856,1.543,6.718,6.718,0,0,1-1.4.741,2.457,2.457,0,0,1-1.871.873,2.413,2.413,0,0,1-1.174-.3h-.052a7.331,7.331,0,0,1-4.5-1.306c.125-1.346,2.134-3.35,4.5-3.35a3.193,3.193,0,0,1,.336.014" transform="translate(-4084.901 -1225.349)" fill="#48360f"/>
    <path id="Path_274" data-name="Path 274" d="M92.257,125.83a2.463,2.463,0,0,0-.887.166,2.585,2.585,0,0,0-.286,4.64,2.4,2.4,0,0,0,1.174.3,2.449,2.449,0,0,0,1.871-.873,2.617,2.617,0,0,0,.547-2.281,2.5,2.5,0,0,0-2.418-1.954m-.57,4.126a1.69,1.69,0,0,1,.26-3.22,2.618,2.618,0,0,0-.658,1.782,2.72,2.72,0,0,0,.4,1.439" transform="translate(-4097.2 -1226.715)" fill="#48360f"/>
    <path id="Path_275" data-name="Path 275" d="M75.39,113.61a7.281,7.281,0,0,0-5.57,2.612l.442.355a6.8,6.8,0,0,1,5.127-2.392,7.964,7.964,0,0,1,5.6,2.406l.419-.383a8.376,8.376,0,0,0-6.017-2.6" transform="translate(-4081.97 -1217.387)" fill="#48360f"/>
    <path id="Path_276" data-name="Path 276" d="M106.835,175.53l.374.232c-.166.168-3.589,4.365-10.829,4.365a16.585,16.585,0,0,1-11.754-4.389l-.4.272a17.182,17.182,0,0,0,12.152,5.021,14.809,14.809,0,0,0,11.224-5.023Z" transform="translate(-4092.97 -1264.656)" fill="#48360f"/>
    <path id="Path_277" data-name="Path 277" d="M124.477,160.012l-.535.547a1.631,1.631,0,0,1,.466,1.339c-.031.132-.066.166-.513.31l-.159.052a8.914,8.914,0,0,1-2.917.485h-.092a8.91,8.91,0,0,1-2.934-.49l-.163-.054c-.426-.142-.461-.173-.49-.3a1.64,1.64,0,0,1,.464-1.339l-.532-.549a2.387,2.387,0,0,0-.677,2.054c.128.573.549.712.994.861l.156.052a9.651,9.651,0,0,0,3.182.532h.092a9.68,9.68,0,0,0,3.161-.525l.151-.05c.457-.149.892-.291,1.02-.871a2.386,2.386,0,0,0-.674-2.051" transform="translate(-4117.49 -1252.808)" fill="#48360f"/>
    <path id="Path_278" data-name="Path 278" d="M86.241,175.43l-.341.234a16.6,16.6,0,0,0,11.754,4.387c7.24,0,10.664-4.2,10.829-4.368l-.374-.232a14.727,14.727,0,0,1-10.455,4.162,17.413,17.413,0,0,1-11.414-4.183" transform="translate(-4094.245 -1264.58)" fill="#3b2c07"/>
  </g>
  </svg>
);

export default RikPin;