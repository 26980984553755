import React from "react";

const SVG = ({
  style = {},
  fill = "#000",
  width = "100%",
  className = "navshadow2",
  viewBox = "0 0 32 32"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {/* <path
      fill={fill}
      d="M 2.56635 12.9241C 0.708307 9.55549 0 6.83983 0 5.00558C 0 3.17134 0.450658 2.64526 0.907953 2.22025C 1.36525 1.79524 3.42732 0.523908 3.77867 0.286808C 4.13002 0.0497085 5.47099 -0.41107 6.31193 0.798636C 7.15287 2.00834 8.73646 4.43718 9.82825 6.05069C 11.5415 8.33611 10.1766 9.33937 9.73572 9.94069C 8.92447 11.0472 8.45734 11.3201 8.45734 12.6788C 8.45734 14.0375 12.2545 17.8976 13.1625 18.8487C 14.0635 19.7926 17.8471 23.1094 19.0195 23.2868C 20.2002 23.4654 21.7807 22.2154 22.1168 21.8985C 23.8263 20.586 24.7912 21.581 25.5787 22.0136C 26.3661 22.4461 29.9239 24.663 31.0264 25.4103C 32.0641 26.1576 31.9992 27.3125 31.9992 27.3125C 31.9992 27.3125 29.859 30.7092 29.5996 31.1168C 29.2753 31.5924 28.4971 32 26.746 32C 24.995 32 23.1241 31.6802 18.6777 29.2349C 15.0396 27.234 11.5714 24.1009 9.75551 22.2666C 7.87475 20.4324 4.68876 16.772 2.56635 12.9241Z"
    /> */}
<circle className="st0" cx="26" cy="30" r="25.98"/>
<path className="st1" d="M50.51,38.67c-0.95-1.39-2.31-2.32-4.06-2.32h-2.4c-2.48,0-0.87,2.01-0.87,4.48v8.68
	C46.47,46.61,49.02,42.89,50.51,38.67z"/>
<path className="st2" d="M42.75,27.69c0,0-7.36-0.52-16.95-0.52c-9.59,0-16.79,0.54-16.79,0.54s7.3,6.24,16.79,6.24
	S43.59,27.74,42.75,27.69"/>
<path className="st3" d="M46.19,28.9c-1.75-2.3-5.82-0.62-5.82-0.62l-14.47,3.56V56c0.03,0,0.06,0,0.09,0c6.58,0,12.58-2.45,17.16-6.48
	V37.9c0,0,0.87-1.74,2.08-3.22C46.45,33.19,47.94,31.19,46.19,28.9z"/>
<path className="st1" d="M7.76,36.35h-2.4c-1.67,0-2.99,0.85-3.93,2.14c1.46,4.22,3.96,7.94,7.21,10.86v-8.52
	C8.64,38.35,10.24,36.35,7.76,36.35z"/>
<path className="st3" d="M5.62,28.9c-1.75,2.3-0.26,4.3,0.95,5.78c1.21,1.48,2.08,3.22,2.08,3.22v11.46c4.58,4.11,10.63,6.61,17.26,6.64
	V31.84l-14.47-3.56C11.44,28.28,7.37,26.6,5.62,28.9z"/>
<path className="st4" d="M11.4,14.61c0,0,2.63,2.27,14.67,2.27c12.33,0,14.38-2.06,14.45-2.14c0,0-7.29-2.61-15.79-2.06
	C21.34,12.9,11.4,14.61,11.4,14.61"/>
<path className="st5" d="M10.35,21.94c0.2,2.02,1.73,3.52,3.42,3.35c1.69-0.17,2.91-1.94,2.71-3.95l-0.11-1.1
	c-0.2-2.02-1.73-3.52-3.42-3.35c-1.69,0.17-2.91,1.94-2.71,3.95L10.35,21.94z"/>
<path className="st5" d="M41.79,21.94c-0.2,2.02-1.73,3.52-3.42,3.35c-1.69-0.17-2.91-1.94-2.71-3.95l0.11-1.1
	c0.2-2.02,1.73-3.52,3.42-3.35c1.69,0.17,2.91,1.94,2.71,3.95L41.79,21.94z"/>
<path className="st6" d="M38.78,22.7c0,5.29-5.04,9.62-11.19,9.62h-3.03c-6.16,0-11.19-4.33-11.19-9.62c0-0.05,0-5.29,0-5.34
	c0.09-5.22,5.09-7.07,11.19-7.07h3.03c6.11,0,11.13,1.88,11.19,7.12C38.78,17.43,38.78,22.66,38.78,22.7"/>
<path className="st7" d="M40.97,8.96c0,2.94-6.7,1.88-14.96,1.88c-8.26,0-14.95,1.06-14.95-1.88c0-2.94,6.7-6.16,14.95-6.16
	C34.28,2.81,40.97,6.03,40.97,8.96"/>
<path className="st4" d="M39.56,9.86c0,0-4.39-1.74-13.54-1.74c-9.15,0-12.97,2.09-12.97,2.09s-0.48,1.96-0.35,2.48
	c0.14,0.52-0.14,0.9,1.94,0.9c2.08,0,25.17-0.26,25.17-0.26S40.08,10.27,39.56,9.86"/>
<path className="st8" d="M40.22,14.73c-0.06,0-0.11-0.01-0.17-0.02c-0.06-0.01-6.17-1.26-14.04-1.26c-8.97,0-13.95,1.24-14,1.25
	c-0.44,0.12-0.9-0.16-1.02-0.6c-0.11-0.45,0.16-0.9,0.6-1.02c0.21-0.05,5.21-1.31,14.41-1.31c8.05,0,14.12,1.25,14.38,1.3
	c0.45,0.09,0.74,0.54,0.65,0.99C40.96,14.46,40.61,14.73,40.22,14.73"/>
<path className="st9" d="M25.79,3.3c-0.23,0-0.41,0.18-0.41,0.41c0,0.23,0.18,0.42,0.41,0.43c8.07,0.18,12.27,2.92,12.31,2.95
	c0.07,0.05,0.15,0.07,0.23,0.07c0.13,0,0.27-0.06,0.35-0.18c0.13-0.19,0.08-0.45-0.11-0.58c-0.18-0.12-4.42-2.91-12.76-3.09
	C25.8,3.3,25.8,3.3,25.79,3.3"/>
<path className="st10" d="M30.76,17.61c-1.41,0-2.56,1.24-2.56,2.05v0.06l0.05,0.04c0.03,0.03,0.84,0.78,2.51,0.78
	c1.57,0,2.47-0.74,2.51-0.77l0.05-0.04v-0.07C33.32,18.98,32.21,17.61,30.76,17.61 M30.93,17.91c0.14-0.05,0.29-0.08,0.45-0.08
	c0.59,0,1.09,0.42,1.22,0.99c0.25,0.28,0.4,0.57,0.43,0.78c-0.1,0.07-0.34,0.23-0.71,0.37c-0.23,0.27-0.57,0.44-0.94,0.44
	c-0.21,0-0.42-0.06-0.59-0.15c-0.01,0-0.02,0-0.03,0c-1.33,0-2.08-0.51-2.27-0.66c0.06-0.68,1.08-1.69,2.27-1.69
	C30.82,17.91,30.87,17.91,30.93,17.91"/>
<path className="st10" d="M31.38,17.83c-0.16,0-0.31,0.03-0.45,0.08c-0.47,0.19-0.81,0.65-0.81,1.2c0,0.49,0.27,0.92,0.66,1.14
	c0.18,0.1,0.38,0.15,0.59,0.15c0.38,0,0.71-0.17,0.94-0.44c0.19-0.23,0.31-0.52,0.31-0.85c0-0.1-0.01-0.21-0.03-0.3
	C32.47,18.25,31.97,17.83,31.38,17.83 M31.09,19.91c-0.31-0.12-0.54-0.43-0.54-0.79c0-0.41,0.29-0.76,0.67-0.83
	c-0.2,0.22-0.33,0.54-0.33,0.9C30.89,19.46,30.97,19.71,31.09,19.91"/>
<path className="st10" d="M30.55,16.37c-1.79,0-2.77,1.26-2.81,1.32l0.22,0.18c0.01-0.01,0.93-1.21,2.59-1.21c1.75,0,2.81,1.2,2.82,1.21
	l0.21-0.19C33.54,17.63,32.43,16.37,30.55,16.37"/>
<path className="st10" d="M21.58,17.61c-1.41,0-2.56,1.24-2.56,2.05v0.06l0.05,0.04c0.03,0.03,0.84,0.78,2.51,0.78
	c1.57,0,2.47-0.74,2.51-0.77l0.05-0.04v-0.07C24.14,18.98,23.03,17.61,21.58,17.61 M21.75,17.91c0.14-0.05,0.29-0.08,0.45-0.08
	c0.59,0,1.09,0.42,1.22,0.99c0.25,0.28,0.4,0.57,0.43,0.78c-0.1,0.07-0.34,0.23-0.71,0.37c-0.23,0.27-0.57,0.44-0.94,0.44
	c-0.21,0-0.42-0.06-0.59-0.15c-0.01,0-0.02,0-0.03,0c-1.33,0-2.08-0.51-2.27-0.66c0.06-0.68,1.08-1.69,2.27-1.69
	C21.64,17.91,21.69,17.91,21.75,17.91"/>
<path className="st10" d="M22.2,17.83c-0.16,0-0.31,0.03-0.45,0.08c-0.47,0.19-0.81,0.65-0.81,1.2c0,0.49,0.27,0.92,0.66,1.14
	c0.18,0.1,0.38,0.15,0.59,0.15c0.38,0,0.71-0.17,0.94-0.44c0.19-0.23,0.31-0.52,0.31-0.85c0-0.1-0.01-0.21-0.03-0.3
	C23.28,18.25,22.79,17.83,22.2,17.83 M21.91,19.91c-0.31-0.12-0.54-0.43-0.54-0.79c0-0.41,0.29-0.76,0.67-0.83
	c-0.2,0.22-0.33,0.54-0.33,0.9C21.71,19.46,21.78,19.71,21.91,19.91"/>
<path className="st10" d="M21.37,16.37c-1.79,0-2.77,1.26-2.81,1.32l0.22,0.18c0.01-0.01,0.93-1.21,2.59-1.21c1.75,0,2.81,1.2,2.82,1.21
	l0.21-0.19C24.36,17.63,23.25,16.37,21.37,16.37"/>
<path className="st10" d="M31.69,23.76l0.19,0.12c-0.08,0.08-1.81,2.2-5.46,2.2c-3.83,0-5.84-2.13-5.93-2.21L20.28,24
	c0.09,0.08,2.3,2.53,6.13,2.53c3.65,0,5.58-2.45,5.66-2.53L31.69,23.76z"/>
<path className="st10" d="M28.22,21.91l-0.27,0.28c0,0,0.31,0.32,0.24,0.68c-0.01,0.07-0.03,0.08-0.26,0.16l-0.08,0.03
	c-0.31,0.1-0.81,0.24-1.47,0.24h-0.05c-0.51,0-1-0.08-1.48-0.25l-0.08-0.03c-0.21-0.07-0.23-0.09-0.25-0.15
	c-0.08-0.36,0.23-0.67,0.23-0.68l-0.27-0.28c-0.02,0.02-0.47,0.46-0.34,1.04c0.06,0.29,0.28,0.36,0.5,0.43l0.08,0.03
	c0.53,0.18,1.05,0.27,1.61,0.27h0.05c0.72,0,1.26-0.15,1.59-0.26l0.08-0.03c0.23-0.08,0.45-0.15,0.51-0.44
	C28.68,22.37,28.23,21.93,28.22,21.91"/>
<path className="st11" d="M20.65,23.75l-0.17,0.12c0.09,0.08,2.1,2.21,5.93,2.21c3.65,0,5.38-2.12,5.46-2.2l-0.19-0.12
	c-0.02,0.02-1.9,2.1-5.28,2.1C22.85,25.86,20.67,23.77,20.65,23.75"/>
<rect x="4.53" y="29.3" className="st12" width="21.68" height="28.48"/>
<rect x="25.6" y="29.3" className="st12" width="21.68" height="28.48"/>
<path className="st13" d="M25.73,48.94 M25.71,48.94C25.71,48.94,25.71,48.94,25.71,48.94C25.71,48.94,25.71,48.94,25.71,48.94
	 M25.7,48.94L25.7,48.94L25.7,48.94 M25.69,48.94L25.69,48.94L25.69,48.94 M25.68,48.94L25.68,48.94L25.68,48.94"/>
<path className="st13" d="M25.57,48.94L25.57,48.94 M25.58,48.94L25.58,48.94L25.58,48.94 M25.58,48.94L25.58,48.94L25.58,48.94
	 M25.61,48.94C25.61,48.94,25.61,48.94,25.61,48.94C25.61,48.94,25.61,48.94,25.61,48.94 M25.62,48.94L25.62,48.94L25.62,48.94
	 M25.62,48.94L25.62,48.94L25.62,48.94 M25.63,48.94C25.63,48.94,25.63,48.94,25.63,48.94C25.63,48.94,25.63,48.94,25.63,48.94
	 M25.64,48.94C25.64,48.94,25.64,48.94,25.64,48.94C25.64,48.94,25.64,48.94,25.64,48.94 M25.64,48.94L25.64,48.94L25.64,48.94
	 M25.65,48.94C25.65,48.94,25.65,48.94,25.65,48.94C25.65,48.94,25.65,48.94,25.65,48.94 M25.66,48.94L25.66,48.94L25.66,48.94
	 M25.66,48.94L25.66,48.94L25.66,48.94"/>
<path className="st14" d="M24.69,47.04L24.69,47.04c0.14,0.39,0.51,0.67,0.95,0.69C25.2,47.71,24.83,47.43,24.69,47.04 M25.63,47.73
	C25.64,47.73,25.64,47.73,25.63,47.73C25.64,47.73,25.64,47.73,25.63,47.73 M25.64,47.73C25.64,47.73,25.64,47.73,25.64,47.73
	C25.64,47.73,25.64,47.73,25.64,47.73 M25.65,47.73L25.65,47.73L25.65,47.73 M25.66,47.73L25.66,47.73L25.66,47.73 M25.66,47.73
	L25.66,47.73L25.66,47.73 M25.67,47.73L25.67,47.73v0.01h2h-2V47.73z"/>
<g>
	<path className="st15" d="M25.67,45.62c-0.58,0-1.05,0.47-1.05,1.05s0.47,1.05,1.05,1.05c0.58,0,1.05-0.47,1.05-1.05
		S26.25,45.62,25.67,45.62 M25.67,48.94c-1.25,0-2.27-1.02-2.27-2.27c0-1.25,1.02-2.27,2.27-2.27c1.25,0,2.27,1.02,2.27,2.27
		C27.94,47.93,26.92,48.94,25.67,48.94"/>
	<g>
		<path className="st16" d="M8.65,45.64h1.36V39.5c0-0.95,0-1.98-0.34-2.49c-0.34-0.52-3.18-3.18-4.09-4.47
			c-0.56-0.8-0.51-2.34-0.39-2.93c-0.99,2,0.3,3.74,1.39,5.07c1.21,1.48,2.08,3.22,2.08,3.22V45.64z"/>
		<path className="st17" d="M46.63,29.6c0.12,0.59,0.18,2.13-0.38,2.93c-0.91,1.29-3.75,3.96-4.09,4.47c-0.34,0.52-0.34,1.55-0.34,2.49
			v6.14h1.36V37.9c0,0,0.87-1.74,2.08-3.22C46.32,33.35,47.62,31.6,46.63,29.6z"/>
		<path className="st18" d="M29.12,49.1c-0.11,0-0.22-0.03-0.32-0.09l-2.91-1.82c-0.28-0.18-0.37-0.55-0.19-0.84
			c0.18-0.28,0.55-0.37,0.84-0.19l2.91,1.82c0.28,0.18,0.37,0.55,0.19,0.84C29.52,49,29.32,49.1,29.12,49.1"/>
		<rect x="8.63" y="45.64" className="st19" width="34.54" height="1.4"/>
		<rect x="8.63" y="47.04" className="st20" width="34.54" height="0.7"/>
		<path className="st21" d="M27.33,47.2c-0.21,0-0.41-0.08-0.56-0.25c-0.28-0.31-0.26-0.79,0.06-1.07l1.64-1.48
			c0.31-0.28,0.79-0.26,1.07,0.06c0.28,0.31,0.26,0.79-0.06,1.07l-1.64,1.48C27.69,47.14,27.51,47.2,27.33,47.2"/>
		<path className="st15" d="M25.67,48.94c-1.25,0-2.27-1.02-2.27-2.27c0-1.25,1.02-2.27,2.27-2.27v1.21c-0.58,0-1.05,0.47-1.05,1.05
			s0.47,1.05,1.05,1.05V48.94z"/>
		<path className="st22" d="M27.67,47.74h-2v1.2h0.01h0c0,0,0,0,0.01,0h0h0.01h0c0,0,0.01,0,0.01,0c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0
			C26.57,48.92,27.29,48.44,27.67,47.74"/>
		<path className="st22" d="M24.68,47.04h-1.25c0.17,1.04,1.06,1.85,2.14,1.9h0c0,0,0,0,0.01,0h0c0,0,0,0,0.01,0h0c0.01,0,0.02,0,0.02,0
			c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0h0h0c0,0,0,0,0,0h0c0,0,0,0,0,0h0h0h0c0,0,0,0,0,0h0h0.01h0h0.01v-1.2v-0.01h-0.01h0
			c0,0,0,0,0,0h0h0h0c0,0,0,0-0.01,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0C25.2,47.71,24.83,47.43,24.68,47.04"/>
		<path className="st17" d="M41.81,50.16c0,0,0.01,0.19-0.01,0.48c0.47-0.36,0.92-0.73,1.36-1.12v-1.84h-1.36V50.16z"/>
		<path className="st16" d="M10.01,47.68H8.65v1.68c0.44,0.39,0.89,0.77,1.36,1.13c-0.01-0.2,0-0.33,0-0.33V47.68z"/>
	</g>
</g>
  </svg>
);

export default SVG;