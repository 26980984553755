import React from "react";

const FullRik = ({
  style = {},
  width = "198px",
  height = "290px",
  className = "",
  viewBox = "0 0 198.42 290.59"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
 <defs>
    <clipPath>
      <rect id="Rectangle_51" data-name="Rectangle 51" width="74.87" height="98.35" transform="translate(25.39 91.49)"/>
    </clipPath>
    <clipPath>
      <rect id="Rectangle_52" data-name="Rectangle 52" width="74.87" height="98.35" transform="translate(98.17 91.49)"/>
    </clipPath>
  </defs>
  <path id="Path_279" data-name="Path 279" d="M43.35,189.47v74.81H95.39V236.85a3.85,3.85,0,1,1,7.7,0v27.43h52.38V189.47Z" fill="#006ca1"/>
  <path id="Path_280" data-name="Path 280" d="M36.55,115.82h-8.3c-12.81,0-19.63,14.42-19.63,27.93v61.39a17.969,17.969,0,0,1,28.41,10.62,15.4,15.4,0,0,0,2.54-8.46V131.29c.01-8.55,5.53-15.47-3.02-15.47" fill="#0074a5"/>
  <path id="Path_281" data-name="Path 281" d="M39.58,207.3v-1.46A24.529,24.529,0,0,0,8.63,197.9v7.25a17.969,17.969,0,0,1,28.41,10.62,15.512,15.512,0,0,0,2.54-8.47" fill="#006ca1"/>
  <path id="Path_282" data-name="Path 282" d="M6.11,222.77c-.08-1.49,0-2,0-3.12,0-9.13,5.9-14.09,13.33-14.09,9.13,0,15.36,4.81,15.36,13.94a16.05,16.05,0,0,1-.33,3.27h4.11A19.429,19.429,0,1,0,0,219.5a18.5,18.5,0,0,0,.3,3.27Z" fill="#0074a5"/>
  <path id="Path_283" data-name="Path 283" d="M6.11,222.57a2.92,2.92,0,1,1-2.92-2.92,2.923,2.923,0,0,1,2.92,2.92" fill="#0074a5"/>
  <path id="Path_284" data-name="Path 284" d="M38.65,222.53a2.15,2.15,0,1,1-2.15-2.15,2.147,2.147,0,0,1,2.15,2.15" fill="#0074a5"/>
  <path id="Path_285" data-name="Path 285" d="M161.87,115.82h8.3c12.82,0,19.63,14.42,19.63,27.93v61.39a17.969,17.969,0,0,0-28.41,10.62,15.4,15.4,0,0,1-2.53-8.46V131.29c-.01-8.55-5.54-15.47,3.01-15.47" fill="#0074a5"/>
  <path id="Path_286" data-name="Path 286" d="M158.85,207.3v-1.46a24.529,24.529,0,0,1,30.95-7.94v7.25a17.969,17.969,0,0,0-28.41,10.62,15.429,15.429,0,0,1-2.54-8.47" fill="#006ca1"/>
  <path id="Path_287" data-name="Path 287" d="M192.31,222.77c.08-1.49,0-2,0-3.12,0-9.13-5.9-14.09-13.33-14.09-9.13,0-15.36,4.81-15.36,13.94a16.05,16.05,0,0,0,.33,3.27h-4.11a19.44,19.44,0,1,1,38.28,0h-5.81Z" fill="#0074a5"/>
  <path id="Path_288" data-name="Path 288" d="M192.31,222.57a2.915,2.915,0,1,0,2.92-2.92,2.923,2.923,0,0,0-2.92,2.92" fill="#0074a5"/>
  <path id="Path_289" data-name="Path 289" d="M159.77,222.53a2.145,2.145,0,1,0,2.15-2.15,2.147,2.147,0,0,0-2.15,2.15" fill="#0074a5"/>
  <path id="Path_290" data-name="Path 290" d="M159.25,127.05l.07-.14H39.29l.07.14v61.77s-1.86,26.53,26.53,26.53h66.84c28.39,0,26.53-26.53,26.53-26.53V127.05Z" fill="#0074a5"/>
  <path id="Path_291" data-name="Path 291" d="M49.12,40.77s9.09,7.83,50.67,7.83c42.57,0,49.64-7.12,49.9-7.38,0,0-25.16-9.02-54.52-7.13-11.74.75-46.05,6.68-46.05,6.68" fill="#9e151a"/>
  <path id="Path_292" data-name="Path 292" d="M157.39,85.91s-25.42-1.78-58.54-1.78S40.87,86,40.87,86s25.22,21.55,57.98,21.55,61.42-21.45,58.54-21.64" fill="#c3b200"/>
  <path id="Path_293" data-name="Path 293" d="M154.06,66.06c-.68,6.97-5.98,12.15-11.83,11.58s-10.03-6.69-9.35-13.65l.37-3.81c.68-6.97,5.98-12.15,11.83-11.58s10.04,6.69,9.35,13.65Z" fill="#df9563"/>
  <path id="Path_294" data-name="Path 294" d="M45.51,66.06c.68,6.97,5.98,12.15,11.83,11.58s10.04-6.69,9.35-13.65l-.37-3.81c-.68-6.97-5.98-12.15-11.82-11.58s-10.04,6.69-9.35,13.65Z" fill="#df9563"/>
  <path id="Path_295" data-name="Path 295" d="M143.65,68.68c0,18.27-17.39,33.21-38.64,33.21H94.56c-21.25,0-38.64-14.94-38.64-33.21V50.24C56.24,32.2,73.5,25.81,94.56,25.81h10.46c21.12,0,38.42,6.48,38.64,24.58-.01.12-.01,18.17-.01,18.29" fill="#eda26a"/>
  <path id="Path_296" data-name="Path 296" d="M151.24,21.26c0,10.14-23.12,6.49-51.64,6.49S47.96,31.4,47.96,21.26,71.08,0,99.6,0s51.64,11.12,51.64,21.26" fill="#be1622"/>
  <path id="Path_297" data-name="Path 297" d="M146.37,24.34S131.2,18.32,99.6,18.32s-44.79,7.2-44.79,7.2-1.67,6.77-1.19,8.56-.48,3.1,6.69,3.1,86.9-.91,86.9-.91.94-10.5-.84-11.93" fill="#9e151a"/>
  <path id="Path_298" data-name="Path 298" d="M148.66,41.18a3.029,3.029,0,0,1-.59-.06A267.006,267.006,0,0,0,99.6,36.76c-30.96,0-48.18,4.29-48.35,4.33a2.884,2.884,0,0,1-1.42-5.59c.72-.18,18.01-4.51,49.77-4.51a267.539,267.539,0,0,1,49.65,4.48,2.886,2.886,0,0,1-.59,5.71" fill="#c01622"/>
  <path id="Path_299" data-name="Path 299" d="M98.83,1.72a1.423,1.423,0,0,0-1.42,1.41A1.44,1.44,0,0,0,98.82,4.6c27.88.61,42.37,10.08,42.52,10.18a1.467,1.467,0,0,0,.8.24,1.444,1.444,0,0,0,.81-2.64c-.61-.41-15.27-10.04-44.06-10.67a.176.176,0,0,1-.06.01" fill="#ca4b3f"/>
  <path id="Path_300" data-name="Path 300" d="M95.39,282.41a8.177,8.177,0,0,1-8.18,8.18H50.09a8.177,8.177,0,0,1-8.18-8.18V265.4a8.177,8.177,0,0,1,8.18-8.18H87.22a8.177,8.177,0,0,1,8.18,8.18v17.01Z" fill="#0074a5"/>
  <path id="Path_301" data-name="Path 301" d="M156.58,282.41a8.177,8.177,0,0,1-8.18,8.18H111.27a8.177,8.177,0,0,1-8.18-8.18V265.4a8.177,8.177,0,0,1,8.18-8.18H148.4a8.177,8.177,0,0,1,8.18,8.18v17.01Z" fill="#0074a5"/>
  <path id="Path_302" data-name="Path 302" d="M99.21,100.24,49.25,87.96s-14.05-5.8-20.08,2.13-.9,14.84,3.27,19.97a69.841,69.841,0,0,1,7.18,11.13v41.23s-1.85,26.37,26.37,26.37H99.21Z" fill="#ffed00"/>
  <path id="Path_303" data-name="Path 303" d="M115.97,51.13c-4.88,0-8.84,4.28-8.84,7.07v.22l.16.15c.12.11,2.9,2.68,8.68,2.68a14.066,14.066,0,0,0,8.65-2.66l.18-.15V58.2c.01-2.35-3.81-7.07-8.83-7.07m.59,1.03a4.188,4.188,0,0,1,1.55-.29,4.358,4.358,0,0,1,4.21,3.41,5.926,5.926,0,0,1,1.49,2.69,11.759,11.759,0,0,1-2.44,1.29,4.272,4.272,0,0,1-3.26,1.52,4.2,4.2,0,0,1-2.05-.53h-.09a12.755,12.755,0,0,1-7.85-2.28c.22-2.35,3.72-5.84,7.85-5.84l.59.03" fill="#48360f"/>
  <path id="Path_304" data-name="Path 304" d="M118.11,51.87a4.188,4.188,0,0,0-1.55.29,4.509,4.509,0,0,0-.49,8.09,4.17,4.17,0,0,0,2.05.53,4.272,4.272,0,0,0,3.26-1.52,4.486,4.486,0,0,0,1.07-2.93,4.421,4.421,0,0,0-.12-1.04,4.377,4.377,0,0,0-4.22-3.42m-1,7.19a2.945,2.945,0,0,1,.46-5.61,4.528,4.528,0,0,0-1.14,3.1,4.776,4.776,0,0,0,.68,2.51" fill="#48360f"/>
  <path id="Path_305" data-name="Path 305" d="M115.26,46.84a12.7,12.7,0,0,0-9.7,4.55l.77.62a11.864,11.864,0,0,1,8.93-4.17,13.855,13.855,0,0,1,9.75,4.19l.73-.67a14.624,14.624,0,0,0-10.48-4.52" fill="#48360f"/>
  <path id="Path_306" data-name="Path 306" d="M84.27,51.13c-4.88,0-8.84,4.28-8.84,7.07v.22l.16.15c.12.11,2.9,2.68,8.68,2.68a14.066,14.066,0,0,0,8.65-2.66l.18-.15V58.2c0-2.35-3.82-7.07-8.83-7.07m.59,1.03a4.175,4.175,0,0,1,1.54-.29,4.358,4.358,0,0,1,4.21,3.41,5.926,5.926,0,0,1,1.49,2.69,11.759,11.759,0,0,1-2.44,1.29,4.272,4.272,0,0,1-3.26,1.52,4.2,4.2,0,0,1-2.05-.53h-.09a12.755,12.755,0,0,1-7.85-2.28c.22-2.35,3.72-5.84,7.85-5.84l.6.03" fill="#48360f"/>
  <path id="Path_307" data-name="Path 307" d="M86.4,51.87a4.174,4.174,0,0,0-1.54.29,4.506,4.506,0,0,0-.5,8.09,4.16,4.16,0,0,0,2.04.53,4.272,4.272,0,0,0,3.26-1.52,4.486,4.486,0,0,0,1.07-2.93,4.421,4.421,0,0,0-.12-1.04,4.34,4.34,0,0,0-4.21-3.42m-.99,7.19a2.933,2.933,0,0,1-1.86-2.74,2.9,2.9,0,0,1,2.31-2.87,4.535,4.535,0,0,0-1.15,3.1,4.88,4.88,0,0,0,.7,2.51" fill="#48360f"/>
  <path id="Path_308" data-name="Path 308" d="M83.55,46.84a12.757,12.757,0,0,0-9.71,4.55l.77.62a11.864,11.864,0,0,1,8.93-4.17,13.855,13.855,0,0,1,9.75,4.19l.73-.67a14.509,14.509,0,0,0-10.47-4.52" fill="#48360f"/>
  <path id="Path_309" data-name="Path 309" d="M119.17,72.36l.65.41c-.29.29-6.25,7.61-18.87,7.61-13.22,0-20.16-7.35-20.48-7.65l-.69.47a29.962,29.962,0,0,0,21.17,8.75,25.85,25.85,0,0,0,19.56-8.75Z" fill="#48360f"/>
  <path id="Path_310" data-name="Path 310" d="M107.19,65.97l-.93.95a2.833,2.833,0,0,1,.81,2.33c-.05.23-.12.29-.89.54l-.28.09a15.612,15.612,0,0,1-5.08.84h-.16a15.566,15.566,0,0,1-5.11-.85l-.28-.1c-.74-.25-.8-.3-.85-.53a2.833,2.833,0,0,1,.81-2.33l-.93-.96a4.136,4.136,0,0,0-1.18,3.58c.22,1,.95,1.24,1.73,1.5l.27.09a16.867,16.867,0,0,0,5.54.93h.16a16.916,16.916,0,0,0,5.51-.91l.27-.09c.8-.26,1.55-.51,1.78-1.52a4.171,4.171,0,0,0-1.19-3.56" fill="#48360f"/>
  <path id="Path_311" data-name="Path 311" d="M81.07,72.32l-.59.41c.32.29,7.26,7.64,20.48,7.64,12.61,0,18.58-7.31,18.87-7.61l-.65-.4a25.653,25.653,0,0,1-18.22,7.25,30.311,30.311,0,0,1-19.89-7.29" fill="#3b2c07"/>
  <rect id="Rectangle_50" data-name="Rectangle 50" width="16.06" height="87.93" transform="translate(95.44 100.33)" fill="#ffed00"/>
  <path id="Path_312" data-name="Path 312" d="M99.21,100.24l49.96-12.28s14.05-5.8,20.08,2.13.9,14.84-3.27,19.97a69.841,69.841,0,0,0-7.18,11.13v41.23s1.85,26.37-26.37,26.37H99.21Z" fill="#ffe900"/>
  <g id="Group_29" data-name="Group 29" opacity="0.1">
    <g id="Group_28" data-name="Group 28">
      <g id="Group_27" data-name="Group 27">
        <path id="Path_313" data-name="Path 313" d="M99.21,188.78v-3.89H65.99c-22.87,0-21.68-21.37-21.68-21.37V126.7c0-3.27,0-6.83-1.19-8.61s-10.99-11-14.13-15.44c-1.95-2.76-1.76-8.06-1.33-10.11-3.43,6.91,1.05,12.93,4.78,17.52a69.841,69.841,0,0,1,7.18,11.13v41.22s-1.85,26.37,26.37,26.37Z"/>
      </g>
    </g>
  </g>
  <g id="Group_32" data-name="Group 32" opacity="0.1">
    <g id="Group_31" data-name="Group 31">
      <g id="Group_30" data-name="Group 30">
        <path id="Path_314" data-name="Path 314" d="M99.21,188.78v-3.89h33.23c22.87,0,21.68-21.37,21.68-21.37V126.7c0-3.27,0-6.83,1.19-8.61s10.99-11,14.12-15.44c1.95-2.76,1.76-8.06,1.33-10.11,3.44,6.91-1.05,12.93-4.78,17.52a69.841,69.841,0,0,0-7.18,11.13v41.22s1.85,26.37-26.37,26.37Z"/>
      </g>
    </g>
  </g>
  <path id="Path_315" data-name="Path 315" d="M110.3,159.87a2.065,2.065,0,0,1-1.11-.32l-10.06-6.29a2.1,2.1,0,1,1,2.22-3.56l10.06,6.29a2.1,2.1,0,0,1,.67,2.89,2.08,2.08,0,0,1-1.78.99" fill="#f4dd00"/>
  <rect id="Rectangle_53" data-name="Rectangle 53" width="119.27" height="4.84" transform="translate(39.56 147.91)" fill="#feed53"/>
  <rect id="Rectangle_54" data-name="Rectangle 54" width="119.27" height="2.42" transform="translate(39.56 152.75)" fill="#fae100"/>
  <path id="Path_316" data-name="Path 316" d="M98.4,147.85a3.635,3.635,0,1,0,3.64,3.63,3.645,3.645,0,0,0-3.64-3.63m0,11.46a7.83,7.83,0,1,1,7.83-7.83,7.842,7.842,0,0,1-7.83,7.83" fill="#ffe700"/>
  <path id="Path_317" data-name="Path 317" d="M104.13,153.3a2.625,2.625,0,0,1-1.76-4.57l5.66-5.11a2.62,2.62,0,1,1,3.51,3.89l-5.66,5.1a2.522,2.522,0,0,1-1.75.69" fill="#fff042"/>
  <rect id="Rectangle_55" data-name="Rectangle 55" width="65.32" height="4.84" transform="translate(93.5 147.91)" fill="#feed53"/>
  <rect id="Rectangle_56" data-name="Rectangle 56" width="65.32" height="2.42" transform="translate(93.5 152.75)" fill="#fae100"/>
  <path id="Path_318" data-name="Path 318" d="M98.4,159.31a7.83,7.83,0,1,1,0-15.66v4.19a3.635,3.635,0,1,0,0,7.27Z" fill="#ffe700"/>
  <path id="Path_319" data-name="Path 319" d="M98.61,159.31m-.07,0h0m-.03,0h0m-.04,0h0m-.03,0h0" fill="#ededed"/>
  <path id="Path_320" data-name="Path 320" d="M105.29,155.16H98.4v4.15h.21a7.83,7.83,0,0,0,6.68-4.15" fill="#ecd735"/>
  <path id="Path_321" data-name="Path 321" d="M98.06,159.3h0m.02.01h0m.02,0h0m.09,0h0m.02,0h0m.02,0h0m.03,0h0m.02,0h0m.02,0h0m.03,0h0m.02,0h0m.02,0h0" fill="#ededed"/>
  <path id="Path_322" data-name="Path 322" d="M95,152.75h-.01a3.641,3.641,0,0,0,3.28,2.37A3.638,3.638,0,0,1,95,152.75m3.27,2.37h0m.03,0h0m.03,0h0m.02,0h0m.02,0h0m.03,0h0v.04h0Z" fill="#e7d20b"/>
  <path id="Path_323" data-name="Path 323" d="M94.99,152.75h-4.3a7.831,7.831,0,0,0,7.37,6.56h.33v-4.19h-.14a3.636,3.636,0,0,1-3.26-2.37" fill="#ecd735"/>




  </svg>
);
export default FullRik;
